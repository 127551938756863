import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {

  return (
    <div className="coming-soon">
      <div className="overlay"></div>
      <div className="content">
        <img src="/logo.png" alt="Logo" className="logo" /> {/* Replace with actual logo */}
        <h1>Website inactive</h1>
        <p>Due to lack of cooperation from the league organisation, the website has been deactivated. Apologies for the inconvenience.</p>
      </div>
    </div>
  );
};

export default ComingSoon;